var render = function () {
  var _vm$validateResult;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "height": "400px",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mb-4 me-3",
          attrs: {
            "color": "primary",
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "17"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDatabaseImportOutline) + " ")]), _c('span', [_vm._v("Import")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_vm.step === 1 ? _c('v-card', {
    attrs: {
      "title": "Import Doctor List"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', [_c('h2', [_vm._v("Import Medical Cards")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.distributorOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Select Distributor"
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Create membership?"
    },
    model: {
      value: _vm.form.create_membership,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "create_membership", $$v);
      },
      expression: "form.create_membership"
    }
  })], 1), _vm.form.create_membership ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.planPasses,
      "item-text": "title",
      "item-value": "value",
      "label": "Select Plan Pass"
    },
    model: {
      value: _vm.form.plan_pass_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "plan_pass_id", $$v);
      },
      expression: "form.plan_pass_id"
    }
  })], 1) : _vm._e(), _vm.form.plan_pass_id ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.cardLayouts,
      "item-value": "id",
      "label": "Select Card Layout"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var _item$instance;
        var item = _ref2.item,
          index = _ref2.index;
        return [_c('div', {
          staticClass: "d-flex align-center bg-surface-variant",
          staticStyle: {
            "height": "124px"
          }
        }, [_c('img', {
          staticStyle: {
            "width": "120px",
            "height": "auto"
          },
          attrs: {
            "src": item === null || item === void 0 ? void 0 : (_item$instance = item.instance) === null || _item$instance === void 0 ? void 0 : _item$instance.thumbnail
          }
        })])];
      }
    }, {
      key: "item",
      fn: function fn(_ref3) {
        var _item$instance2;
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-center bg-surface-variant",
          staticStyle: {
            "height": "150px"
          }
        }, [_c('img', {
          staticStyle: {
            "width": "60px",
            "height": "auto"
          },
          attrs: {
            "src": item === null || item === void 0 ? void 0 : (_item$instance2 = item.instance) === null || _item$instance2 === void 0 ? void 0 : _item$instance2.thumbnail
          }
        }), _vm._v(" (#" + _vm._s(item === null || item === void 0 ? void 0 : item.id) + ") ")])];
      }
    }, {
      key: "default",
      fn: function fn() {
        return [_c('strong', {
          staticClass: "text-primary py-1"
        }, [_vm._v("m")])];
      },
      proxy: true
    }], null, false, 765960643),
    model: {
      value: _vm.form.card_layout_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "card_layout_id", $$v);
      },
      expression: "form.card_layout_id"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-file-input', {
    attrs: {
      "chips": "",
      "accept": ".csv",
      "label": "Upload .csv file"
    },
    model: {
      value: _vm.form.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Override data if card exists",
      "color": "red"
    },
    model: {
      value: _vm.form.override,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "override", $$v);
      },
      expression: "form.override"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error",
      "outlined": "",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Import")])], 1)], 1) : _vm._e(), _vm.step === 2 ? _c('v-card', [_c('v-card-title', [_vm._v("Validate Result")]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.validateResult === null ? _c('div', {
    staticClass: "result-container d-flex align-center justify-center"
  }, [_vm._v(" Validating... ")]) : _c('div', [_c('v-simple-table', {
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', [_c('span', {
          staticClass: "text-success"
        }, [_vm._v("Valid")])]), _c('td', [_vm._v(_vm._s(_vm.validateResult.valid))])]), _c('tr', [_c('td', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("Invalid")])]), _c('td', [_vm._v(_vm._s(_vm.validateResult.invalid))])]), _c('tr', [_c('td', [_vm._v("Total")]), _c('td', [_vm._v(_vm._s(_vm.validateResult.total))])])])];
      },
      proxy: true
    }], null, false, 1433024484)
  }), _vm.validateResult.invalid > 0 ? _c('div', [_c('v-data-table', {
    staticClass: "mt-5",
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.validateResult.invalid_records
    },
    scopedSlots: _vm._u([{
      key: "item.errors",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('ol', _vm._l(item.errors, function (error, index) {
          return _c('li', {
            key: index
          }, [_vm._v(" " + _vm._s(error) + " ")]);
        }), 0)];
      }
    }], null, true)
  }), _c('p', {
    staticClass: "mt-5 text-danger"
  }, [_vm._v("Please fix invalid data shown in above table, then import again")])], 1) : _vm._e()], 1)])], 1)], 1)], 1), ((_vm$validateResult = _vm.validateResult) === null || _vm$validateResult === void 0 ? void 0 : _vm$validateResult.invalid) > 0 ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("OK")])], 1) : _vm._e()], 1) : _vm._e(), _vm.step === 3 ? _c('v-card', {
    attrs: {
      "loading": _vm.stats === null
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Import Result")])]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.stats === null ? _c('div', {
    staticClass: "result-container d-flex align-center justify-center"
  }, [_vm._v(" Importing... ")]) : _c('div', [_c('p', [_c('span', {
    staticClass: "text-success"
  }, [_vm._v("Success:")]), _vm._v(" " + _vm._s(_vm.stats.succeed))]), _c('p', [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Failed:")]), _vm._v(" " + _vm._s(_vm.stats.failed))]), _vm.stats.failed > 0 ? _c('v-data-table', {
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.stats.failed_records,
      "hide-default-footer": "",
      "disable-pagination": ""
    }
  }) : _vm._e()], 1)])], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("OK")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
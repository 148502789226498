<template>
  <div>
    <v-dialog v-model="isDialogVisible" max-width="600px" height="400px" persistent>
      <!-- Dialog Activator -->
      <template #activator="{ on, attrs }">
        <v-btn color="primary" outlined class="mb-4 me-3" v-bind="attrs" v-on="on">
          <v-icon size="17" class="me-1">
            {{ icons.mdiDatabaseImportOutline }}
          </v-icon>
          <span>Import</span>
        </v-btn>
      </template>

      <!-- Dialog Content -->
      <v-card title="Import Doctor List" v-if="step === 1">
        <v-card-text>
          <v-row>
            <v-col>
              <h2>Import Medical Cards</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.distributor_id"
                :items="distributorOptions"
                item-text="title"
                item-value="value"
                label="Select Distributor"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="form.create_membership"
                label="Create membership?"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" v-if="form.create_membership">
              <v-autocomplete
                v-model="form.plan_pass_id"
                :items="planPasses"
                item-text="title"
                item-value="value"
                label="Select Plan Pass"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" v-if="form.plan_pass_id">
              <v-select v-model="form.card_layout_id" :items="cardLayouts" item-value="id" label="Select Card Layout">
                <template v-slot:selection="{ item, index }">
                    <div class="d-flex align-center bg-surface-variant" style="height: 124px">
                      <img :src="item?.instance?.thumbnail" style="width: 120px; height: auto">
                    </div>
                </template>
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center bg-surface-variant" style="height: 150px">
                    <img :src="item?.instance?.thumbnail" style="width: 60px; height: auto;"> (#{{ item?.id}})
                  </div>
                </template>

                <template v-slot:>
                  <strong class="text-primary py-1">m</strong>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-file-input v-model="form.file" chips accept=".csv" label="Upload .csv file" />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="form.override"
                label="Override data if card exists"
                color="red"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error" outlined @click="closeDialog" :disabled="loading">Close</v-btn>
          <v-btn color="success" @click="handleSubmit" :disabled="loading" :loading="loading">Import</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="step === 2">
        <v-card-title>Validate Result</v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="result-container d-flex align-center justify-center" v-if="validateResult === null">
                  Validating...
                </div>

                <div v-else>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td><span class="text-success">Valid</span></td>
                          <td>{{ validateResult.valid }}</td>
                        </tr>
                        <tr>
                          <td><span class="text-danger">Invalid</span></td>
                          <td>{{ validateResult.invalid }}</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>{{ validateResult.total }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-if="validateResult.invalid > 0">
                    <v-data-table
                      :headers="tableHeaders"
                      :items="validateResult.invalid_records"
                      class="mt-5"
                    >
                      <template #[`item.errors`]="{ item }">
                        <ol>
                          <li v-for="error, index in item.errors" :key="index">
                            {{ error }}
                          </li>
                        </ol>
                      </template>
                    </v-data-table>

                    <p class="mt-5 text-danger">Please fix invalid data shown in above table, then import again</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="validateResult?.invalid > 0">
          <v-spacer />
          <v-btn color="primary" @click="closeDialog" :disabled="loading">OK</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="step === 3" :loading="stats === null">
        <v-card-title>
          <span class="text-h5">Import Result</span>
        </v-card-title>
        <v-card-text>
          <v-container>
          <v-row>
            <v-col cols="12">
              <div class="result-container d-flex align-center justify-center" v-if="stats === null">
                Importing...
              </div>

              <div v-else>
                <p><span class="text-success">Success:</span> {{ stats.succeed }}</p>
                <p><span class="text-danger">Failed:</span> {{ stats.failed }}</p>
                <v-data-table
                  :headers="tableHeaders"
                  :items="stats.failed_records"
                  hide-default-footer
                  disable-pagination
                  v-if="stats.failed > 0"
                >
                </v-data-table>
              </div>
            </v-col>
          </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="closeDialog" :disabled="loading">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cardImportService } from '../../services'

import { mdiPlus, mdiDatabaseImportOutline } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import Pusher from 'pusher-js'
import { fetchPasses } from '@/api/product/pass'
import { fetchCardLayouts } from '@/api/medical/cardLayout'
import { useDistributor } from '@/composables'

export default {
  setup(_, { emit }) {
    const { distributorOptions } = useDistributor()

    const isDialogVisible = ref(false)
    const loading = ref(false)

    const step = ref(1)
    const validateResult = ref(null)
    const stats = ref(null)
    const planPasses = ref([])
    const cardLayouts = ref([])

    const initialFormData = () => {
      return {
        distributor_id: null,
        create_membership: false,
        plan_pass_id: null,
        card_layout_id: null,
        file: null,
        override: false,
      }
    }

    const form = ref({ ...initialFormData() })

    const resetForm = () => {
      step.value = 1
      validateResult.value = null
      form.value = initialFormData()
    }

    const closeDialog = () => {
      resetForm()

      isDialogVisible.value = false
    }

    const handleSubmit = async () => {
      loading.value = true

      try {
        const uploadForm = new FormData()
        uploadForm.append('distributor_id', form.value.distributor_id)
        uploadForm.append('file', form.value.file)
        uploadForm.append('override', form.value.override ? 1 : 0)

        if (form.value.plan_pass_id) {
          uploadForm.append('plan_pass_id', form.value.plan_pass_id)
          uploadForm.append('card_layout_id', form.value.card_layout_id)
        }

        // Validation
        step.value = 2

        const { status } = await cardImportService.store(uploadForm)

        if (status === 'success') {
          resetForm()

          step.value = 3
        }
      } catch ({ response }) {
        if (response) {
          if (response.status === 422) {
            validateResult.value = response.data.data
          }
        }
      } finally {
        loading.value = false
      }
    }

    const getPlanPassList = async () => {
      const { status, data } = await fetchPasses({
        distributor_id: form.value.distributor_id,
        type: 'medical',
        owner: 'individual',
        paginate: 0,
      })

      if (status !== 200) {
        return
      }

      planPasses.value = data.data.map(pass => {
        return {
          title: pass.title.en,
          value: pass.id,
        }
      })
    }

    watch(
      [() => form.value.distributor_id, () => form.value.create_membership],
      ([newDistributorId, newCreateMembershipState], [oldDistributorId, oldCreateMembershipState]) => {
        if (newDistributorId === null || !newCreateMembershipState) {
          planPasses.value = []

          return
        }

        if (newDistributorId !== oldDistributorId || newCreateMembershipState) {
          getPlanPassList()
        }
      },
    )

    watch(
      () => form.value.plan_pass_id,
      () => {
        if (form.value.plan_pass_id) {
          fetchCardLayouts({
            plan_pass_id: form.value.plan_pass_id,
            paginate: 0,
          }).then(({ status, data }) => {
            if (status !== 200) {
              return
            }

            cardLayouts.value = data.data
          })
        }
      },
    )

    const tableHeaders = [
      {
        text: 'Record ID',
        value: 'id',
        sortable: false,
      },
      {
        text: 'Errors',
        value: 'errors',
        sortable: false,
      },
    ]

    // Subscribe to pusher in order to get import result
    const subscribeToPusherChannel = () => {
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, getPusherOption())

      pusher.subscribe(`admin.medical_card`).bind('imported', event => {
        stats.value = event.stats

        emit('imported')
      })
    }

    const getPusherOption = () => {
      if (process.env.NODE_ENV === 'production') {
        return {
          cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        }
      }

      return {
        wsHost: process.env.VUE_APP_PUSHER_WS_HOST,
        wsPort: process.env.VUE_APP_PUSHER_WS_PORT,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
      }
    }

    onMounted(() => {
      subscribeToPusherChannel()
    })

    return {
      distributorOptions,

      isDialogVisible,
      loading,

      step,
      validateResult,
      stats,

      form,

      planPasses,
      cardLayouts,

      icons: {
        mdiPlus,
        mdiDatabaseImportOutline,
      },

      closeDialog,
      handleSubmit,

      tableHeaders,
    }
  },
}
</script>

<style lang="scss" scoped>
.result-container {
  min-height: 200px;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}
</style>

<template>
  <div>
    <filter-section :cols="[3,3,3]" class="mb-3">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
      <distributor-id-filter
        slot="col_1"
        v-model="filters"
      />

      <status-filter
        slot="col_2"
        v-model="filters"
        :options="statusOptions"
      />
    </filter-section>

    <v-card>
      <!-- actions -->
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="paginate"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>
        <div class="d-flex justify-space-between">
          <import-dialog
            @imported="paginate"
          />

          <v-btn
            color="success"
            outlined
            class="mb-4"
            :loading="loading"
            :disabled="loading"
            @click="exportCards"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiDatabaseExportOutline }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <card-table
        ref="tableRef"
        :table-list="tableList"
      />
    </v-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiRefresh } from '@mdi/js'
import { FilterSection, SearchFilter, DistributorIdFilter, StatusFilter } from '@/components'
import { useOldDataFetch, useTableList } from '@/composables'
import { cardService, cardExportService } from '../services'
import CardTable from '../components/list/Table.vue'
import ImportDialog from '../components/list/ImportDialog'
import TerminateDialog from '../components/list/TerminateDialog'

export default {
  components: {
    FilterSection,
    SearchFilter,
    DistributorIdFilter,
    StatusFilter,

    CardTable,
    ImportDialog,
    TerminateDialog,
  },

  setup() {
    const filters = ref({
      search: null,
      distributor_id: null,
      status: null,
    })

    const tableList = useTableList(cardService, filters)
    const { loading, tableRef, paginate } = tableList

    const statusOptions = ref([
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
      { title: 'Expired', value: 'expired' },
      { title: 'Suspended', value: 'suspended' },
      { title: 'Cancelled', value: 'cancelled' },
      { title: 'Voided', value: 'voided' },
      { title: 'Terminated', value: 'terminated' },
    ])

    const { parseParam } = useOldDataFetch()

    const exportCards = async () => {
      loading.value = true
      try {
        await cardExportService.store(
          parseParam({
            ...filters.value,
          }),
        )
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      tableRef,
      paginate,
      tableList,

      t,
      dayjs,
      formatDate,

      filters,
      statusOptions,

      exportCards,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiRefresh,
        mdiDatabaseExportOutline,
      },
    }
  },
}
</script>

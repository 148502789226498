import axios from '@axiosV2'

const store = async params => {
  const response = await axios.post('/admin/cards/export', params, {
    responseType: 'arraybuffer',
  })

  let blob = new Blob([response.data])
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'medical_cards.xlsx'
  link.click()
}

export default { store }
